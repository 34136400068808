<template>
  <v-container
    fluid
    class="pt-0"
  >
    <v-data-table
      :headers="headers"
      :items="bonificacao"
      height="192"
      class="elevation-1"
    >
      <template v-slot:[`item.quantidade`]="{item}">
        <v-edit-dialog
          :return-value.sync="item.quantidade"
          persistent
          save-text="Salvar"
          large
          cancel-text="Cancelar"
        >
          <span>{{ item.quantidade }}</span>
          <v-icon
            left
            small
            class="pl-2"
            color="info darken-3"
          >
            mdi-pencil
          </v-icon>
          <template v-slot:input>
            <v-text-field
              v-model="item.quantidade"
              v-mask="'##########'"
              single-linedense
              label="Edit"
              autofocus
              single-line
              outlined
              rounded
              class="mt-5"
            />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon
          color="#D50000"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
  export default {
    name: 'PedidoBonificacaoDialog',
    components: {
    },
    props: {
      bonificacao: {
        type: Array,
        required: true,
      },
      deleteItem: {
        type: Function,
        required: true,
      },
    },
    data () {
      return {
        headers: [
          { text: '', value: 'actions', sortable: false },
          { text: 'Código', value: 'codigo_produto' },
          { text: 'Nome', value: 'nome' },
          { text: 'Quantidade', value: 'quantidade' },
          { text: 'Unidade', value: 'unidade_medida' },
          { text: 'Marca', value: 'marca' },
        ],
      }
    },
  }
</script>
