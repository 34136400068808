<template>
  <v-dialog
    v-model="dialog"
    max-width="1700px"
    max-height="800px"
    width="90%"
    scrollable
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-toolbar-title
          v-if="loading"
        >
          {{ 'Pedido' }}
        </v-toolbar-title>
        <v-toolbar-title
          v-else
        >
          {{ 'Editando Pedido' }}
        </v-toolbar-title>

        <v-spacer />
        <v-btn
          icon
          dark
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-6">
        <base-loading v-if="loading" />
        <div v-else>
          <v-form
            ref="form"
            dense
            lazy-validation
          >
            <v-card
              style="margin-bottom: 10px;"
              elevation="0"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    v-model="pedido_id"
                    dense
                    label="Pedido Nº"
                    class="text-direita"
                    readonly
                    outlined
                    rounded
                    hide-details="auto"
                    style="font-weight: bold;"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    dense
                    :value="data_pedido?.substring(0, 10)"
                    label="Data"
                    hide-details="auto"
                    type="date"
                    readonly
                    outlined
                    rounded
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    :value="currencyFormatter(valor_total)"
                    dense
                    label="Valor total"
                    class="text-direita"
                    hide-details="auto"
                    prefix="R$"
                    readonly
                    outlined
                    rounded
                    style="font-weight: bold;"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <data-field
                    v-model="data_prevista_entrega"
                    :label="'Data ideal para entrega'"
                    :data-min="data_pedido"
                    style-text-field=""
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="nome_produtor"
                    hide-details="auto"
                    dense
                    label="Produtor"
                    readonly
                    outlined
                    rounded
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="nome_fazenda"
                    hide-details="auto"
                    dense
                    label="Fazenda"
                    readonly
                    outlined
                    rounded
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="5"
                >
                  <v-text-field
                    v-model="statusPrevious"
                    hide-details="auto"
                    dense
                    label="Status"
                    readonly
                    outlined
                    rounded
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="1"
                >
                  <v-icon
                    v-if="!$vuetify.breakpoint.xs"
                    large
                    class="d-flex align-center"
                  >
                    mdi-chevron-triple-right
                  </v-icon>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-autocomplete
                    v-model="status"
                    hide-details="auto"
                    dense
                    autocomplete="off"
                    :items="listStatus"
                    :rules="[rules.required]"
                    label="Status"
                    validate-on-blur
                    outlined
                    rounded
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-autocomplete
                    v-model="parcelas"
                    hide-details="auto"
                    dense
                    autocomplete="off"
                    :items="arrayParcelamento"
                    item-text="text"
                    item-value="value"
                    :rules="[rules.required]"
                    label="Parcelamento"
                    validate-on-blur
                    outlined
                    rounded
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    v-model="resposta"
                    maxlength="255"
                    hide-details="auto"
                    dense
                    height="65"
                    no-resize
                    label="Observação"
                    :rules="[...(observacaoRequired() ? [rules.required] : [])]"
                    validate-on-blur
                    outlined
                    rounded
                  />
                </v-col>
              </v-row>
            </v-card>
            <v-tabs
              v-model="tabs"
              class="px-1 pt-2 mb-2"
              icons-and-text
              style="height: 55px;"
            >
              <v-tabs-slider />
              <v-tab
                style="font-size: smaller;"
              >
                Produtos
                <v-icon>mdi-package-variant-closed</v-icon>
              </v-tab>
              <v-tab
                style="font-size: smaller;"
              >
                Bonificações
                <v-icon>mdi-gift-outline</v-icon>
              </v-tab>
              <v-tab
                style="font-size: smaller;"
              >
                Parcelamento
                <v-icon>mdi-cash-multiple</v-icon>
              </v-tab>
              <v-tab
                style="font-size: smaller;"
                @click="downloadImage(signature_id)"
              >
                Tipo de atendimento
                <v-icon>mdi-face-agent</v-icon>
              </v-tab>
            </v-tabs>

            <v-tabs-items
              v-model="tabs"
              mandatory
            >
              <v-tab-item>
                <v-row
                  class="px-1"
                >
                  <v-col
                    cols="12"
                    class="pt-0 my-1"
                  >
                    <crud-list
                      v-model="produtosPedido"
                      :headers="headers"
                      :actions="actions"
                      :slots="['item.quantidade', 'item.valor_subtotal', 'item.valor_total', 'item.valor_unitario', 'item.desconto_negociado', 'item.valor_desconto', 'item.perc_desconto']"
                      :item-height="$vuetify.breakpoint.xl ? '200' : '200'"
                      :show-search="false"
                      :editable-field="true"
                    >
                      <template v-slot:[`item.valor_subtotal`]="{ item }">
                        <span>{{ currencyFormatter(item.valor_subtotal) }}</span>
                      </template>

                      <template v-slot:[`item.valor_desconto`]="{ item }">
                        <span>{{ currencyFormatter(item.valor_desconto) }}</span>
                      </template>

                      <template v-slot:[`item.valor_total`]="{ item }">
                        <span>{{ currencyFormatter(stringToNumber(item.valor_total)) }}</span>
                      </template>

                      <template v-slot:[`item.valor_unitario`]="{ item }">
                        <span>{{ currencyFormatter(item.valor_unitario) }}</span>
                      </template>

                      <template v-slot:[`item.desconto_negociado`]="{ item }">
                        <span>{{ currencyFormatter(item.desconto_negociado) + '%' }}</span>
                      </template>

                      <template v-slot:[`item.perc_desconto`]="{ item }">
                        <span>{{ currencyFormatter(item.perc_desconto) + '%' }}</span>
                      </template>

                      <template v-slot:[`item.quantidade`]="{ item }">
                        <v-edit-dialog
                          ref="editDialog1"
                          :return-value.sync="item.quantidade"
                          persistent
                          save-text="Salvar"
                          cancel-text="Cancelar"
                          large
                          @save="saveEditQtd( item )"
                        >
                          <span>{{ item.quantidade }}</span>
                          <v-icon
                            left
                            small
                            class="pl-2"
                            color="info darken-3"
                          >
                            mdi-pencil
                          </v-icon>
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.quantidade"
                              v-mask="'##########'"
                              :rules="[rules.required, rules.geq(1)]"
                              dense
                              label="Edit"
                              autofocus
                              single-line
                              outlined
                              rounded
                              class="mt-5"
                            />
                          </template>
                        </v-edit-dialog>
                      </template>
                    </crud-list>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <pedido-bonificacao-dialog
                  :bonificacao="bonificacao"
                  :delete-item="deletarBonificacao"
                />
              </v-tab-item>
              <v-tab-item>
                <v-card
                  outlined
                  class="mt-1"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-card
                        min-height="258"
                        elevation="2"
                      >
                        <v-simple-table
                          :height="$vuetify.breakpoint.xl ? '258' : '200'"
                          fixed-header
                          elevation="0"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th
                                  class="text-right"
                                >
                                  Parcela
                                </th>
                                <th
                                  class="text-right"
                                >
                                  Valor da Parcela
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, index) in parcelamento.parcelado1"
                                :key="index"
                              >
                                <td

                                  style="text-align: right;"
                                >
                                  {{ item.parcela1 + 'º' }}
                                </td>
                                <td

                                  style="text-align: right;"
                                >
                                  {{ 'R$ ' + item.valor1 }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-card
                        elevation="2"
                        min-height="258"
                      >
                        <v-simple-table
                          :height="$vuetify.breakpoint.xl ? '258' : '200'"
                          fixed-header
                          elevation="0"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th
                                  class="text-right"
                                >
                                  Parcela
                                </th>
                                <th
                                  class="text-right"
                                >
                                  Valor da Parcela
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, index) in parcelamento.parcelado2"
                                :key="index"
                              >
                                <td

                                  style="text-align: right;"
                                >
                                  {{ item.parcela2 + 'º' }}
                                </td>
                                <td

                                  style="text-align: right;"
                                >
                                  {{ 'R$ ' + item.valor2 }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-row
                    class="mt-1 mx-4"
                  >
                    <v-col cols="2">
                      <v-radio-group
                        v-model="tipo_atendimento"
                        readonly
                      >
                        <v-radio
                          label="Presencial"
                          :value="0"
                        />
                        <v-radio
                          label="Telefone"
                          :value="1"
                        />
                        <v-radio
                          label="Online"
                          :value="2"
                        />
                      </v-radio-group>
                    </v-col>

                    <v-col
                      v-if="tipo_atendimento == 0 && signature"
                      cols="12"
                      md="3"
                      class="justify-center text-center"
                    >
                      <h4 class="text-h4 primary--text">
                        Assinatura do produtor
                      </h4>
                      <v-img
                        max-width="400px"
                        class="rounded-xl"
                        :src="signature"
                        style="object-fit: contain; border: 0.5px solid #42424220;"
                      />
                    </v-col>

                    <v-col
                      v-if="tipo_atendimento == 0 && !signature"
                      cols="12"
                      md="3"
                      class="justify-center text-center"
                    >
                      <base-loading />
                    </v-col>

                    <v-col
                      v-if="tipo_atendimento == 1"
                      cols="3"
                      class="mt-4"
                    >
                      <v-row class="d-block">
                        <v-col>
                          <v-text-field
                            dense
                            :value="data_atendimento?.substring(0, 10)"
                            label="Data do atendimento"
                            hide-details="auto"
                            type="date"
                            outlined
                            readonly
                            rounded
                          />
                        </v-col>

                        <v-col>
                          <v-text-field
                            dense
                            :value="observacao_atendimento"
                            label="Observação"
                            hide-details="auto"
                            outlined
                            readonly
                            rounded
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions
        class="justify-end"
      >
        <v-btn
          class="text-none text-white"
          color="primary"
          rounded
          @click="exportarPdf"
        >
          <v-icon
            dark
            left
          >
            mdi-file-pdf
          </v-icon>
          Imprimir PDF
        </v-btn>
        <v-btn
          class="text-none text-white"
          color="error"
          rounded
          @click="cancel"
        >
          <v-icon
            dark
            left
          >
            mdi-minus-circle
          </v-icon>
          Cancelar
        </v-btn>
        <v-btn
          class="text-none text-white"
          color="success"
          depressed
          rounded
          @click="submit"
        >
          <v-icon
            dark
            left
          >
            mdi-checkbox-marked-circle
          </v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { BOOTSTRAP_PROFILE, CLOSE, SET_STATE, SUBMIT, DELETE_BONIFICACAO } from '@/store/modules/forms/pedido'
  import { DELETE_PEDIDO } from '@/store/modules/pedidos'
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
  import { statusPedido, statusPedidoFitString, statusPedidoFit } from '../../utils/constants'
  import { currencyFormatter } from '../../utils/formatter'
  import { stringToNumber } from '../../utils/parser'
  import { sortCurrency } from '../../utils/sorts'
  import moment from 'moment'
  import axios from '../../api/axios'
  import PedidoBonificacaoDialog from './PedidoBonificacaoDialog.vue'

  export default {
    name: 'PedidoForm',
    components: {
      PedidoBonificacaoDialog,
    },
    props: {
      dadosDialog: {
        type: Object,
        default: () => {},
      },
      exportPdf: {
        type: Function,
        default: () => {},
      },
    },
    data () {
      return {
        currencyFormatter,
        stringToNumber,
        moment,
        tabs: null,
        statusPedidoFitString,
        statusPedidoFit,
        statusPedido,
        axios,
        money: {
          locale: 'pt-BR',
          precision: 2,
        },
        actions: [
          {
            icon: 'mdi-delete',
            title: 'Excluir',
            click: this.deletarProduto,
            color: '#D50000',
          },
        ],

        headers: [
          { align: 'center', sortable: false, text: 'Ações', value: 'actions', width: 'auto' },
          { align: 'left', sortable: true, text: 'Produto', value: 'nome', width: 'auto' },
          { align: 'center', sortable: false, text: 'UN', value: 'unidade_medida', width: '2' },
          { align: 'right', sortable: true, text: 'Quantidade', value: 'quantidade', width: '5', editable: true },
          { align: 'right', sortable: true, text: 'Volume', value: 'volume', width: 'auto' },
          { align: 'right', sortable: true, text: 'Preço Unitário (R$)', value: 'valor_unitario', width: 'auto', sort: sortCurrency },
          { align: 'right', sortable: true, text: 'Valor Bruto (R$)', value: 'valor_subtotal', width: 'auto', sort: sortCurrency },
          { align: 'right', sortable: true, text: 'Desc. Negociado', value: 'desconto_negociado', width: 'auto', sort: sortCurrency },
          { align: 'right', sortable: true, text: 'Desc. Percentual', value: 'perc_desconto', width: 'auto', sort: sortCurrency },
          { align: 'right', sortable: true, text: 'Valor Desconto (R$)', value: 'valor_desconto', width: 'auto', sort: sortCurrency },
          { align: 'right', sortable: true, text: 'Valor Líquido (R$)', value: 'valor_total', width: 'auto', sort: sortCurrency },
          { align: 'right', sortable: true, text: 'Previsão de Compra', value: 'previsao_compra', width: 'auto', editable: true },
        ],

        updatedItens: [],
        arrayParcelamento: [],

        MAX_PARCELAS: 12,
        signature: '',
        statusInitial: this.status,
      }
    },
    computed: {
      ...mapState('form/pedido', ['loading', 'rules', 'dialog']),
      ...mapFields('form', [
        'data.id',
        'data.user_id',
        'data.pedido_id',
        'data.nome_produtor',
        'data.nome_fazenda',
        'data.statusPrevious',
        'data.status',
        'data.data_pedido',
        'data.parcelas',
        'data.valor_total',
        'data.observacao',
        'data.resposta',
        'data.data_prevista_entrega',
        'data.tipo_atendimento',
        'data.data_atendimento',
        'data.observacao_atendimento',
        'data.signature_id',
        'data.bonificacao',
      ]),
      ...mapMultiRowFields('form', [
        'data.logs',
        'data.itens',
      ]),
      listStatus () {
        if (this.statusPrevious === this.statusPedidoFit.aceite_produtor) {
          return [
            this.statusPedidoFit.aguardando_aprovacao,
            this.statusPedidoFit.em_analise,
            this.statusPedidoFit.aprovado,
            this.statusPedidoFit.cancelado,
            this.statusPedidoFit.faturado,
            this.statusPedidoFit.entregue_transportadora,
            this.statusPedidoFit.entregue_tecnico,
          ]
        }

        if (this.statusPrevious === this.statusPedidoFit.aprovado) {
          return [
            this.statusPedidoFit.em_analise,
            this.statusPedidoFit.cancelado,
            this.statusPedidoFit.faturado,
            this.statusPedidoFit.entregue_transportadora,
            this.statusPedidoFit.entregue_tecnico,
          ]
        }

        if (this.statusPrevious === this.statusPedidoFit.aguardando_aprovacao) {
          return [
            this.statusPedidoFit.em_analise,
            this.statusPedidoFit.cancelado,
            this.statusPedidoFit.aprovado,
            this.statusPedidoFit.faturado,
            this.statusPedidoFit.entregue_transportadora,
            this.statusPedidoFit.entregue_tecnico,
          ]
        }

        if (this.statusPrevious === this.statusPedidoFit.em_analise) {
          return [
            this.statusPedidoFit.cancelado,
            this.statusPedidoFit.aprovado,
            this.statusPedidoFit.faturado,
            this.statusPedidoFit.entregue_transportadora,
            this.statusPedidoFit.entregue_tecnico,
          ]
        }

        if (this.statusPrevious === this.statusPedidoFit.faturado) {
          return [
            this.statusPedidoFit.aprovado,
            this.statusPedidoFit.cancelado,
            this.statusPedidoFit.entregue_transportadora,
            this.statusPedidoFit.entregue_tecnico,
          ]
        }

        if (this.statusPrevious === this.statusPedidoFit.entregue_transportadora) {
          return [
            this.statusPedidoFit.cancelado,
            this.statusPedidoFit.faturado,
            this.statusPedidoFit.entregue_tecnico,
          ]
        }

        if (this.statusPrevious === this.statusPedidoFit.entregue_tecnico) {
          return [
            this.statusPedidoFit.cancelado,
            this.statusPedidoFit.faturado,
            this.statusPedidoFit.entregue_transportadora,
          ]
        }

        return [
          this.statusPedidoFit.aguardando_aprovacao,
          this.statusPedidoFit.em_analise,
          this.statusPedidoFit.aprovado,
          this.statusPedidoFit.cancelado,
          this.statusPedidoFit.faturado,
          this.statusPedidoFit.entregue_transportadora,
          this.statusPedidoFit.entregue_tecnico,
        ]
      },
      produtosPedido () {
        return this.itens ? this.itens : []
      },
      parcelamento () {
        const parcelado1 = []
        const parcelado2 = []
        if (this && this.parcelas) {
          if (this.parcelas > 0) {
            let soma = 0
            for (let i = 0; i < this.parcelas; i++) {
              const valor = (this.valor_total / this.parcelas)
              soma += parseFloat(valor.toFixed(2))
              if (i < 6) {
                if (soma > this.valor_total) {
                  parcelado1.push({ parcela1: i + 1, valor1: currencyFormatter((valor - (soma - this.valor_total))) })
                } else if (soma < this.valor_total && i === this.parcelas - 1) {
                  parcelado1.push({ parcela1: i + 1, valor1: currencyFormatter((valor + (this.valor_total - soma))) })
                } else {
                  parcelado1.push({ parcela1: i + 1, valor1: currencyFormatter(valor) })
                }
              } else {
                if (soma > this.valor_total) {
                  parcelado2.push({ parcela2: i + 1, valor2: currencyFormatter((valor - (soma - this.valor_total))) })
                } else if (soma < this.valor_total && i === this.parcelas - 1) {
                  parcelado2.push({ parcela2: i + 1, valor2: currencyFormatter((valor + (this.valor_total - soma))) })
                } else {
                  parcelado2.push({ parcela2: i + 1, valor2: currencyFormatter(valor) })
                }
              }
            }
          }
        }
        return { parcelado1, parcelado2 }
      },

      listParcelamento () {
        const arrayParcelamento = []

        if (!this.valor_total) return arrayParcelamento

        for (let i = 1; i <= this.MAX_PARCELAS; i++) {
          arrayParcelamento.push({
            text: `${i} x R$ ${currencyFormatter((this.valor_total / i))}`,
            value: i,
          })
        }

        return arrayParcelamento
      },
    },
    watch: {
      loading: {
        handler (value) {
          if (!value) {
            this.updatedItens = this.itens.map(item => {
              return {
                valor_desconto: item.valor_desconto,
                id: item.id,
              }
            })

            this.itens.forEach(item => {
              this.saveQuantidade(
                item,
              )
            })
            this.arrayParcelamento = this.parcelamentos()
          }
        },
      },

    },

    methods: {
      ...mapActions('pedidos', [DELETE_PEDIDO]),
      ...mapMutations('form/pedido', [CLOSE, SET_STATE]),
      ...mapActions('form/pedido', [SUBMIT, BOOTSTRAP_PROFILE, DELETE_BONIFICACAO]),
      exportarPdf () {
        this.exportPdf(this.dadosDialog)
      },
      observacaoRequired () {
        return this.status === this.statusPedido.cancelado
      },
      submit () {
        if (!this.$refs.form.validate()) {
          return null
        } else if (!this.validateQtd()) {
          return window.Toast.fire('Quantidade Incorreta', 'itens com quantidade menor que 1', 'error')
        } else {
          return this.SUBMIT()
        }
      },
      cancel () {
        this.CLOSE()
      },
      saveQuantidade (event) {
        const valor_total = event.valor_unitario * event.quantidade
        event.valor_subtotal = valor_total

        const vlrDescontoNegociado = Number(event.desconto_negociado) * Number(event.valor_subtotal) / 100

        const vlrDescontoCampanha = Number(event.perc_desconto) * Number(event.valor_subtotal) / 100

        let valor_desconto = vlrDescontoNegociado + vlrDescontoCampanha

        if (!valor_desconto) valor_desconto = 0

        event.valor_desconto = valor_desconto
        event.valor_total = stringToNumber(event.valor_subtotal) - valor_desconto

        this.valor_total = this.produtosPedido.reduce((acc, item) => acc + item.valor_total, 0)

        const arrayParcelamento = []

        if (!this.valor_total) return arrayParcelamento

        for (let i = 1; i <= this.MAX_PARCELAS; i++) {
          arrayParcelamento.push({
            text: `${i} x R$ ${currencyFormatter((this.valor_total / i))}`,
            value: i,
          })
        }
        return arrayParcelamento
      },
      saveEditQtd (event) {
        this.arrayParcelamento = this.saveQuantidade(event)
      },
      // methodo temporario pois nao tem regra de parcelamento
      parcelamentos () {
        const arrayParcelamento = []

        if (!this.valor_total) return arrayParcelamento

        arrayParcelamento.push({
          text: `${this.parcelas} x R$ ${currencyFormatter((this.valor_total / this.parcelas))}`,
          value: this.parcelas,
        })
        return arrayParcelamento
      },
      /* bloco comentado por ser methodo de parcelamento inicial falta implementar
      methodo real de parcelamento
      parcelamentos () {
        const arrayParcelamento = []

        if (!this.valor_total) return arrayParcelamento

        for (let i = 1; i <= this.MAX_PARCELAS; i++) {
          arrayParcelamento.push({
            text: `${i} x R$ ${currencyFormatter((this.valor_total / i))}`,
            value: i,
          })
        }

        return arrayParcelamento
      },
      */
      async downloadImage (signature_id) {
        if (!signature_id) return

        const url = `${axios.defaults.baseURL}/public/image/pedidos/signatures/${this.signature_id}`

        const response = await axios.get(url, { responseType: 'blob' })
        const reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onload = () => {
          const img = new Image()
          img.src = reader.result
          img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            canvas.width = img.height
            canvas.height = img.width
            ctx.translate(canvas.width / 2, canvas.height / 2)
            ctx.rotate(90 * Math.PI / 180)
            ctx.drawImage(img, -img.width / 2, -img.height / 2)

            this.signature = canvas.toDataURL('image/png')
          }
        }
      },
      setDataEntrega (teste) {
        const partes = teste.split('-')
        const data = new Date(partes[0], partes[1] - 1, partes[2])
        this.data_prevista_entrega = this.moment(data).format('yyyy-MM-DD HH:mm:ss')
      },
      validateQtd () {
        return this.produtosPedido.every(item => item.quantidade >= 1)
      },
      deletarProduto (produto) {
        if (this.produtosPedido.length === 1) {
          return window.Toast.fire('Atenção...', 'Pedido deve conter ao menos um item', 'error')
        }
        this.DELETE_PEDIDO(produto).then(() => this.BOOTSTRAP_PROFILE({ id: produto.pedido_id }))
      },
      deletarBonificacao (produto) {
        this.DELETE_BONIFICACAO(produto).then(() => this.BOOTSTRAP_PROFILE({ id: produto.pedido_id }))
      },
    },
  }
</script>
<style>
.v-tabs--icons-and-text > .v-tabs-bar .v-tab {
  font-size: smaller;
}

.v-slide-group__content {
    height: 45px;
    font-size: 13px;
}

.text-direita input {
  text-align: right;
}

.theme--light > .v-small-dialog__menu-content {
    border-radius: 10px;
  }

.v-btn > .v-btn--text > .theme--light > .v-size--default > .primary--text {
  background-color: black !important;
}
</style>
