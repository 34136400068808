<template>
  <v-dialog
    v-model="dialog"
    max-width="1300px"
    max-height="800px"
    width="90%"
    scrollable
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-col
          cols="11.5"
        >
          <v-toolbar-title>
            {{ 'Transferir Pedido entre Unidades de Negócio' }}
          </v-toolbar-title>
        </v-col>
        <v-btn
          icon
          dark
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-6">
        <base-loading v-if="loading" />
        <div
          v-else
        >
          <v-card
            style="margin-bottom: 10px;"
            elevation="0"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  :value="numero_pedido"
                  dense
                  label="Pedido Nº"
                  class="text-direita"
                  readonly
                  outlined
                  rounded
                  hide-details="auto"
                  style="font-weight: bold;"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  dense
                  :value="data_pedido?.substring(0, 10)"
                  label="Data"
                  hide-details="auto"
                  type="date"
                  readonly
                  outlined
                  rounded
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  :value="currencyFormatter(valor_total)"
                  dense
                  label="Valor total"
                  class="text-direita"
                  hide-details="auto"
                  prefix="R$"
                  readonly
                  outlined
                  rounded
                  style="font-weight: bold;"
                />
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  dense
                  :value="data_entrega?.substring(0, 10)"
                  label="Data ideal para entrega"
                  hide-details="auto"
                  type="date"
                  readonly
                  outlined
                  rounded
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="nome_produtor"
                  hide-details="auto"
                  dense
                  label="Produtor"
                  readonly
                  outlined
                  rounded
                  style="font-weight: bold;"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="nome_fazenda"
                  hide-details
                  dense
                  label="Fazenda"
                  readonly
                  outlined
                  rounded
                  style="font-weight: bold;"
                />
              </v-col>
            </v-row>
            <v-card
              class="mt-5 pa-3"
              outlined
              :style="{
                borderRadius: '12px',
                color: 'black',
              }"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="unidade_negocio"
                    hide-details
                    dense
                    label="Unidade de Negócio atual"
                    readonly
                    outlined
                    rounded
                    color="#1C4EFF"
                    style="font-weight: bold;"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="1"
                >
                  <v-icon
                    v-if="!$vuetify.breakpoint.xs"
                    large
                    class="d-flex align-center"
                  >
                    mdi-chevron-triple-right
                  </v-icon>
                </v-col>
                <v-col
                  cols="12"
                  sm="5"
                >
                  <v-autocomplete
                    v-model="unidades"
                    :items="uNegocio"
                    hide-details
                    item-text="filial"
                    item-value="id"
                    class="d-flex justify-space-between"
                    label="Unidade de Negócio de destino*"
                    clearable
                    dense
                    rounded
                    outlined
                    autocomplete="nope"
                  >
                    <template v-slot:selection="data">
                      {{ data.item.filial }}
                    </template>
                    <template
                      v-slot:item="data"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.filial }}</v-list-item-title>
                        <v-list-item-subtitle>{{ ( data.item.cnpj + '  -  ' + data.item.razao_social ) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </div>
      </v-card-text>
      <v-card-actions
        class="justify-end"
      >
        <v-btn
          class="text-none text-white"
          color="success"
          rounded
          @click="submit"
        >
          <v-icon
            dark
            left
          >
            mdi-checkbox-marked-circle
          </v-icon>
          Confirmar
        </v-btn>
        <v-btn
          class="text-none text-white"
          color="error"
          rounded
          @click="cancel"
        >
          <v-icon
            dark
            left
          >
            mdi-minus-circle
          </v-icon>
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapMutations, mapState, mapActions } from 'vuex'
  import { CLOSE, SET_STATE, SUBMIT } from '@/store/modules/pedidotransfere'
  import { datetimeFormatter, currencyFormatter } from '../../utils/formatter'

  export default {
    name: 'PedidoTransferencia',
    components: {
    },
    data () {
      return {
        unidades: '',
        snackbar: { message: '', show: false },
        datetimeFormatter,
        currencyFormatter,
      }
    },
    computed: {
      ...mapState('pedidotransfere', ['loading', 'dialog', 'pedido_id', 'numero_pedido', 'data_pedido', 'data_entrega', 'valor_total', 'nome_produtor', 'nome_fazenda', 'unidade_negocio', 'uNegocio']),
    },
    methods: {
      ...mapMutations('pedidotransfere', [CLOSE, SET_STATE]),
      ...mapActions('pedidotransfere', [SUBMIT]),
      cancel () {
        this.CLOSE()
      },
      submit () {
        if (this.unidades != '') {
          this.SUBMIT({ unidade: this.unidades, id: this.pedido_id })
          this.unidades = ''
          return null
        } else {
          this.snackbar = {
            show: true,
            message: 'É necessário preencher uma Unidade de Negócio de destino. Por favor, preencha!',
          }
          return null
        }
      },
    },
  }
</script>
