<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="!ready" />
        <div
          v-if="ready"
          :key="status"
        >
          <crud-list
            ref="crudListPedido"
            v-model="pedidos"
            :headers="headers"
            total-column
            :slots="[
              'item.data_pedido',
              'item.data_prevista_entrega',
              'item.status',
              'item.valor_total',
            ]"
            :actions="actions"
            :permission="'list_pedido'"
            :sort-by="['status', 'data_pedido', 'data_prevista_entrega']"
            :sort-desc="[false,true,true]"
            show-expand
          >
            <template slot="datas">
              <v-col
                cols="6"
              >
                <data-field
                  v-model="data_inicial"
                  :label="'Data Inicial'"
                  :data-max="data_final"
                  @set="handleDataFilter()"
                />
              </v-col>
              <v-col
                cols="6"
                align-self="center"
              >
                <data-field
                  v-model="data_final"
                  :label="'Data Final'"
                  :data-min="data_inicial"
                  @set="handleDataFilter()"
                />
              </v-col>
            </template>
            <template v-slot:btnCadastro>
              <v-col>
                <v-btn
                  color="primary"
                  rounded
                  class="text-none"
                  @click="exportarXLSX(null)"
                >
                  Exportar XLSX
                </v-btn>
              </v-col>
            </template>
            <template v-slot:[`item.data_pedido`]="{ item }">
              <span>{{ moment( item.data_pedido ).format( "DD/MM/YYYY - HH:mm:ss") }}</span>
            </template>
            <template v-slot:[`item.data_prevista_entrega`]="{ item }">
              <span>{{ moment(item.data_prevista_entrega).format("DD/MM/YYYY - HH:mm:ss") }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                outlined
                :color="statusPedidoColor(item.status)"
                @click="filterStatus(item.status)"
              >
                <v-avatar left>
                  <v-icon>{{ statusPedidoIcon(item.status) }}</v-icon>
                </v-avatar>
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td
                v-if="item.itens.length || item.bonificacao.length"
                :colspan="headers.length"
              >
                <v-simple-table
                  v-if="item.itens.length"
                  :height="$vuetify.breakpoint.xl ? '300' : '200'"
                  class="my-1 mt-2"
                  dense
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Produtos
                        </th>
                        <th class="text-center">
                          UN
                        </th>
                        <th class="text-right">
                          Quantidade
                        </th>
                        <th class="text-right">
                          Volume
                        </th>
                        <th class="text-right">
                          Preço Unitário
                        </th>
                        <th class="text-right">
                          Vlr. Bruto
                        </th>
                        <th
                          v-if="item.tecnico"
                          class="text-right"
                        >
                          Desc. Negociado
                        </th>
                        <th class="text-right">
                          Desc. %
                        </th>
                        <th class="text-right">
                          Vlr. Desconto
                        </th>
                        <th class="text-right">
                          Vlr. Líquido
                        </th>
                        <th class="text-right">
                          Prev. de Compra
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="( prod, index ) in item.itens"
                        :key="index"
                      >
                        <td
                          class="text-left"
                          width="500"
                        >
                          {{ prod.codProduto + ' - ' + prod.nome }}
                        </td>
                        <td
                          width="50"
                          class="text-center"
                        >
                          {{ prod.unidade_medida }}
                        </td>
                        <td
                          width="50"
                          class="text-right"
                        >
                          {{ prod.quantidade }}
                        </td>
                        <td
                          width="50"
                          class="text-right"
                        >
                          {{ prod.volume }}
                        </td>
                        <td
                          width="50"
                          class="text-right"
                        >
                          R$ {{ currencyFormatter( prod.valor_unitario ) }}
                        </td>
                        <td
                          width="50"
                          class="text-right"
                        >
                          R$ {{ currencyFormatter( prod.valor_subtotal ) }}
                        </td>
                        <td
                          v-if="item.tecnico"
                          width="20"
                          class="text-right"
                        >
                          {{ currencyFormatter( prod.desconto_negociado ) }}%
                        </td>
                        <td
                          width="50"
                          class="text-right"
                        >
                          {{ currencyFormatter(prod.perc_desconto) }}%
                        </td>
                        <td
                          width="50"
                          class="text-right"
                        >
                          R$ {{ currencyFormatter( prod.valor_desconto ) }}
                        </td>
                        <td
                          width="50"
                          class="text-right"
                        >
                          R$ {{ currencyFormatter(prod.valor_total || 0) }}
                        </td>
                        <td
                          width="50"
                          class="text-right"
                        >
                          {{ prod.previsao_compra }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-simple-table
                  v-if="item.bonificacao.length"
                  :height="$vuetify.breakpoint.xl ? '300' : '200'"
                  class="my-1 mt-2"
                  dense
                  style="max-width: 46% !important;"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          class="text-left"
                        >
                          Produtos Bonificados
                        </th>
                        <th class="text-center">
                          UN
                        </th>
                        <th class="text-right">
                          Quantidade
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="( bonifica, index ) in item.bonificacao"
                        :key="index"
                      >
                        <td
                          class="text-left"
                          width="500"
                        >
                          {{ bonifica.codProduto + ' - ' + bonifica.nome }}
                        </td>
                        <td
                          width="50"
                          class="text-center"
                        >
                          {{ bonifica.unidade_medida }}
                        </td>
                        <td
                          width="50"
                          class="text-right"
                        >
                          {{ bonifica.quantidade }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
            <template v-slot:[`item.valor_total`]="{ item }">
              <span>R$ {{ currencyFormatter(item.valor_total || 0) }}</span>
            </template>
          </crud-list>
          <pedido-dialog
            :dados-dialog="dadosPedido"
            :export-pdf="exportPDF"
          />
          <pedido-logs-dialog />
          <pedido-transferencia-dialog />
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import store from '@/store'
  import PedidoStore, { CLOSE } from '@/store/modules/forms/pedido'
  import PedidosStore, { BOOTSTRAP, EDIT_PEDIDO, DELETE_PEDIDO, HISTORICO_PEDIDO, TRANSFERE_PEDIDO } from '@/store/modules/pedidos'
  import UserProfileStore, { LOAD_NOTIFICATIONS } from '@/store/modules/userProfile'
  import PedidoDialog from './PedidoDialog.vue'
  import PedidoLogsDialog from './PedidoLogsDialog.vue'
  import PedidoTransferenciaDialog from './PedidoTransferenciaDialog.vue'
  import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
  import { currencyFormatter, dateFormatter } from '../../utils/formatter'
  import { statusPedido, statusPedidoColor, statusPedidoIcon, statusPedidoString } from '../../utils/constants'
  import { sortDateTime, sortStatusPedido } from '../../utils/sorts'
  import axios from '../../api/axios'
  import JsPDF from 'jspdf'
  import XLSX from 'xlsx'

  export default {
    components: {
      PedidoDialog,
      PedidoLogsDialog,
      PedidoTransferenciaDialog,
    },
    props: {
      status: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        axios,
        currencyFormatter,
        dateFormatter,
        dadosPedido: {},
        statusPedido,
        statusPedidoColor,
        statusPedidoIcon,
        statusPedidoString,
        data_inicial: 'from',
        data_final: 'to',
        headers: [
          { text: '', sortable: false, groupable: false, floatingfilter: false, value: 'actions', width: '100' },
          { align: 'center', sortable: false, groupable: true, floatingfilter: true, text: 'Status', value: 'status', width: 'auto', sort: sortStatusPedido, report: true },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Número do Pedido', value: 'pedido_id', width: 'auto', report: true, styleReport: { string: true } },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Data do Pedido', value: 'data_pedido', width: 'auto', sort: sortDateTime, report: true, styleReport: { halign: 'center' } },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Data da entrega', value: 'data_prevista_entrega', width: 'auto', sort: sortDateTime, report: true, styleReport: { halign: 'center' } },
          { align: 'center', sortable: true, groupable: true, floatingfilter: true, text: 'Matrícula', value: 'cod_produtor_fornecedor', width: 'auto', report: true },
          { align: 'center', sortable: true, groupable: true, floatingfilter: true, text: 'Matr. Clube', value: 'cod_produtor_cliente', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Produtor', value: 'nome_produtor', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Fazenda', value: 'nome_fazenda', width: 'auto', report: true },
          { align: 'left', sortable: false, groupable: false, floatingfilter: true, text: 'Origem', value: 'Origem.descricao', width: 'auto' },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Supervisor / Técnico', value: 'tecnico.nome', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: true, floatingfilter: true, text: 'Unidade de Negócio', value: 'unidadeNegocio.nome_fantasia', width: 'auto' },
          { align: 'right', sortable: true, groupable: false, floatingfilter: false, text: 'Valor Total', value: 'valor_total', sum: 'Money', width: 'auto', report: true, styleReport: { halign: 'right' } },
          { align: 'center', sortable: true, groupable: false, floatingfilter: false, text: 'Parcelas', value: 'parcelas', width: 'auto' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: false, text: 'Observação', value: 'observacao', width: 'auto', report: true },
        ],
        actions: [
          {
            title: 'Editar Pedido',
            color: 'info darken-3',
            click: item => this.editarPedido(item),
            icon: 'mdi-pencil',
            permission: 'editar_pedido',
          },
          {
            title: 'Transferência de Unidade de Negócio',
            color: '#1C4EFF',
            click: item => this.TRANSFERE_PEDIDO(item),
            icon: 'mdi-autorenew',
            permission: 'historico_pedido',
          },
          {
            title: 'Histórico do Pedido',
            color: 'info darken-3',
            click: item => this.HISTORICO_PEDIDO(item),
            icon: 'mdi-history',
            permission: 'historico_pedido',
          },
          {
            title: 'Exportar para PDF',
            color: '#D50000',
            click: item => this.exportPDF(item),
            icon: 'mdi-file-pdf',
            permission: 'imprimir_pedido',
          },
          {
            title: 'Exportar para XLSX',
            color: '#66BB6A',
            click: item => this.exportarXLSX(item),
            icon: 'mdi-microsoft-excel',
            permission: 'imprimir_pedido',
          },
        ].filter(({ permission }) => store.getters.hasPermission(permission)),
      }
    },
    computed: {
      ...mapState('pedidos', ['ready', 'pedidos']),
      ...mapGetters(['hasPermission']),
    },
    created () {
      if (!this.$store.state.form.pedido) { this.$store.registerModule(['form', 'pedido'], PedidoStore) }
      if (!this.$store.hasModule('pedidos')) { this.$store.registerModule('pedidos', PedidosStore) }
      if (!this.$store.state.userProfile) { this.$store.registerModule('userProfile', UserProfileStore) }

      const now = new Date()
      const data_i = new Date(now.getFullYear(), now.getMonth() - 1, 1)
      const data_f = new Date(now.getFullYear(), now.getMonth() + 1, 0)

      this.data_inicial = data_i.toISOString().slice(0, 10)
      this.data_final = data_f.toISOString().slice(0, 10)

      this.BOOTSTRAP({ data_inicial: data_i.toISOString().slice(0, 10), data_final: data_f.toISOString().slice(0, 10) })
    },
    updated () {
      if (this.status !== '') {
        this.filterStatus(this.status)
      }
    },
    destroyed () {
      this.$store.dispatch('userProfile/LOAD_NOTIFICATIONS')
    },
    methods: {
      ...mapActions('pedidos', [BOOTSTRAP, EDIT_PEDIDO, DELETE_PEDIDO, HISTORICO_PEDIDO, TRANSFERE_PEDIDO]),
      ...mapActions('userProfile', [LOAD_NOTIFICATIONS]),
      ...mapMutations('form/pedido', [CLOSE]),
      handleDataFilter () {
        const data_i = this.data_inicial
        const data_f = this.data_final
        this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f })
      },
      filterStatus (status) {
        const elemento = this.$refs.crudListPedido.filters.status.indexOf(status)
        if (this.$refs.crudListPedido.filters.status && elemento === -1) {
          this.$refs.crudListPedido.filters.status.splice(0, this.$refs.crudListPedido.filters.status.length)
          this.$refs.crudListPedido.filters.status.push(status)
        } else {
          this.$refs.crudListPedido.filters.status.splice(elemento, 1)
        }
      },
      exportarXLSX (dados) {
        function formataMoney (valor) {
          valor = valor.toFixed(2)
          valor = valor.toString()
          valor = valor.replace('.', ',')
          valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
          return valor
        }
        function parcelamento (valor, parcelas) {
          if (parcelas > 1) {
            valor = parseFloat(valor)
            valor = valor / parcelas
            valor = formataMoney(valor)
          }
          return valor
        }

        const wb = XLSX.utils.book_new()
        const ws_data = []
        if (dados) {
          ws_data.push(['Pedido', 'Técnico', 'Origem', 'Unidade de Negócio', 'Fazenda', 'Matrícula Clube', 'Matrícula', 'Produtor', 'Quantidade de Parcelas', 'Valor das Parcelas', 'Valor Total', 'Aprovação Produtor', 'Observação'])
          ws_data.push([dados.pedido_id, dados.tecnico ? dados.tecnico.nome : 'Não possui tecnico atribuido', dados.Origem ? dados.Origem.descricao : '', dados.unidadeNegocio.cod_clube + ' - ' + dados.unidadeNegocio.nome_fantasia, dados.nome_fazenda, dados.cod_produtor_cliente, dados.cod_produtor_fornecedor, dados.nome_produtor, dados.parcelas, parcelamento(dados.valor_total, dados.parcelas), dados.valor_total, dados.tipo_aceite, dados.observacao])
          ws_data.push(['', '', '', '']) // Espaço em branco para simular separação

          ws_data.push(['Código', 'Descrição', 'UN', 'Qtd.', 'Vol.', 'Preço', 'Desc. %', 'Preço Desc.', 'Vlr. Bruto', 'Vlr. Desc.', 'Vlr. Líquido', 'Observação'])
          dados.itens.forEach(item => {
            ws_data.push([
              item.codProduto,
              item.nome,
              item.unidade_medida,
              item.quantidade,
              item.volume,
              parseFloat(item.valor_unitario),
              parseFloat(item.desconto_negociado),
              item.valor_total / item.quantidade,
              parseFloat(item.valor_subtotal),
              parseFloat(item.valor_desconto),
              parseFloat(item.valor_total),
              item.observacao ? item.observacao : '',
            ])
          })
        } else {
          const registros = this.$refs.crudListPedido.valueFiltered
          ws_data.push([
            'Status',
            'Pedido',
            'Data Pedido',
            'Data Prevista Entrega',
            'Matrícula',
            'Matrícula Clube',
            'Nome Produtor',
            'Nome Fazenda',
            'Município',
            'Técnico',
            'Unidade de Negocio',
            'Valor Total',
            'Parcelas',
            'Código Produto',
            'Descrição Produto',
            'Unidade de Medida',
            'Quantidade',
            'Preço Unitario',
            'Valor Bruto',
            'Desconto Negociado',
            'Valor Desconto',
            'Valor Líquido',
            'Observação',
          ])
          if (registros.length > 0) {
            registros.forEach(pedido => {
              if (pedido.itens.length === 0) {
                ws_data.push([
                  pedido.status,
                  pedido.pedido_id ? pedido.pedido_id : '',
                  { v: pedido.data_pedido, t: 'd' },
                  pedido.data_prevista_entrega ? { v: pedido.data_prevista_entrega, t: 'd' } : '',
                  pedido.cod_produtor_fornecedor ? pedido.cod_produtor_fornecedor : '',
                  pedido.cod_produtor_cliente ? pedido.cod_produtor_cliente : '',
                  pedido.nome_produtor ? pedido.nome_produtor : '',
                  pedido.nome_fazenda ? pedido.nome_fazenda : '',
                  pedido.cidade_fazenda ? pedido.cidade_fazenda : '',
                  pedido.tecnico ? pedido.tecnico.nome : '',
                  pedido.unidadeNegocio.cod_clube ? pedido.unidadeNegocio.cod_clube + ' - ' + (pedido.unidadeNegocio.nome_fantasia ? pedido.unidadeNegocio.nome_fantasia : '') : '',
                  pedido.valor_total ? pedido.valor_total : '',
                  pedido.parcelas ? pedido.parcelas : '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  pedido.observacao ? pedido.observacao : '',
                ])
              } else {
                pedido.itens.forEach(item => {
                  ws_data.push([
                    pedido.status,
                    pedido.pedido_id ? pedido.pedido_id : '',
                    { v: pedido.data_pedido, t: 'd' },
                    pedido.data_prevista_entrega ? { v: pedido.data_prevista_entrega, t: 'd' } : '',
                    pedido.cod_produtor_fornecedor ? pedido.cod_produtor_fornecedor : '',
                    pedido.cod_produtor_cliente ? pedido.cod_produtor_cliente : '',
                    pedido.nome_produtor ? pedido.nome_produtor : '',
                    pedido.nome_fazenda ? pedido.nome_fazenda : '',
                    pedido.cidade_fazenda ? pedido.cidade_fazenda : '',
                    pedido.tecnico ? pedido.tecnico.nome : '',
                    pedido.unidadeNegocio.cod_clube ? pedido.unidadeNegocio.cod_clube + ' - ' + (pedido.unidadeNegocio.nome_fantasia ? pedido.unidadeNegocio.nome_fantasia : '') : '',
                    { v: pedido.valor_total, t: 'n' },
                    { v: pedido.parcelas, t: 'n' },
                    item.codProduto ? item.codProduto : '',
                    item.nome ? item.nome : '',
                    item.unidade_medida,
                    { v: item.quantidade, t: 'n' },
                    { v: item.valor_unitario, t: 'n' },
                    { v: item.valor_subtotal, t: 'n' },
                    { v: item.desconto_negociado, t: 'n' },
                    { v: item.valor_desconto, t: 'n' },
                    { v: item.valor_total, t: 'n' },
                    pedido.observacao ? pedido.observacao : '',
                  ])
                })
              }
            })
          }
        }

        const ws = XLSX.utils.aoa_to_sheet(ws_data)

        const colWidths = ws_data[0].map((_, colIndex) => ws_data.reduce((maxWidth, currentRow) => {
          const cellValue = currentRow[colIndex]
          const cellWidth = (typeof cellValue === 'string' ? cellValue.length : 0)
          return Math.max(maxWidth, cellWidth)
        }, 0))

        ws['!cols'] = colWidths.map(width => ({ wch: width + 2 }))
        XLSX.utils.book_append_sheet(wb, ws, 'Relatório')
        XLSX.writeFile(wb, 'Relatorio.xlsx')
      },
      exportPDF (dados) {
        const spaceLine = 7
        const fontSize = 10
        const fontTitleSize = fontSize * 2
        const margin = { left: 10, right: 10, bottom: 10 }

        function getY (y) {
          if ((y + fontSize) > docHeight) {
            doc.addPage()
            i = 1
            return fontSize
          } else {
            return y
          }
        }
        function addBonificacao (doc, bonificoes) {
          doc.setFontSize(fontSize)
          doc.setFont('helvetica', 'bold')
          doc.text('Bonificação', margin.left, doc.lastAutoTable.finalY + 4)
          doc.line(margin.left, doc.lastAutoTable.finalY + 6, docWidth - margin.right, doc.lastAutoTable.finalY + 6)
          doc.autoTable({
            theme: 'plain',
            styles: { lineColor: [0, 0, 0] },
            columnStyles: {
              codProduto: { halign: 'center', cellWidth: 16 },
              nome: { cellWidth: 80 },
              unidade_medida: { cellWidth: 10 },
              quantidade: { halign: 'right', cellWidth: 12 },

            },
            headStyles: { lineWidth: { top: 0.2, right: 0, bottom: 0.2, left: 0 }, valor_total: { halign: 'right' } },
            startY: doc.lastAutoTable.finalY + 6,
            columns: [
              { dataKey: 'codProduto' },
              { dataKey: 'nome' },
              { dataKey: 'unidade_medida' },
              { dataKey: 'quantidade' },
            ],
            body: bonificoes,

            margin: { top: 43, left: 10, right: 10, bottom: 10 },

          })
          doc.line(margin.left, doc.lastAutoTable.finalY, docWidth - margin.right, doc.lastAutoTable.finalY)
        }
        function formataMoney (valor) {
          valor = valor.toFixed(2)
          valor = valor.toString()
          valor = valor.replace('.', ',')
          valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
          return valor
        }
        function parcelamento (valor, parcelas) {
          if (parcelas > 1) {
            valor = (valor.replace('.', '')).replace(',', '.')
            valor = parseFloat(valor)
            valor = valor / parcelas
            valor = formataMoney(valor)
          }
          return valor
        }
        function addFooter (pageNumber) {
          doc.setFontSize(fontSize - 1)
          doc.text(dateNow, margin.left - 5, docHeight - margin.bottom)
          doc.text('https://clubedoprodutor.com.br', docWidth / 2, docHeight - margin.bottom, { align: 'center' })
          doc.text('Página ' + pageNumber + ' / ' + totalPages, docWidth + 17, docHeight - margin.bottom, { align: 'right' })
        }
        function addHeader () {
          doc.addImage(window.location.origin + '/img/logo_branca.png', 'png', margin.left, 5, 52, 18, null, 'MEDIUM')
          doc.setFontSize(fontTitleSize)
          doc.text('Pedido', docWidth / 2, 15, { align: 'center' })
          doc.setFontSize(fontSize)
          doc.text('Data: ' + dateFormatter(dados.data_pedido), docWidth - margin.right, 21, { align: 'right' })
          doc.text(dados.status, docWidth / 2, 21, { align: 'center' })
          doc.line(margin.left, 24, docWidth - margin.right, 24)
          doc.autoTable({
            theme: 'plain',
            startY: 25,
            styles: { textColor: 20, fillColor: [255, 255, 255] },
            body: [
              ['Pedido: ' + dados.pedido_id, 'Técnico: ' + (dados.tecnico ? dados.tecnico.nome : 'Não possui técnico atribuido'), 'Unidade de Negócio: ' + dados.unidadeNegocio.cod_clube + ' - ' + dados.unidadeNegocio.nome_fantasia],
              ['Matrícula: ' + dados.cod_produtor_fornecedor, 'Produtor: ' + dados.nome_produtor, 'Origem: ' + (dados.Origem ? dados.Origem.descricao : 'Sem origem')],
              ['Matrícula Clube: ' + dados.cod_produtor_cliente, 'Fazenda: ' + dados.nome_fazenda, 'Aprovação pelo Produtor: ' + dados.tipo_aceite],
            ],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.cellPadding = { top: 1, right: 1.7638888888888886, bottom: 1, left: 1.7638888888888886 }
              } else {
                data.cell.styles.cellPadding = { top: 0.7, right: 1.7638888888888886, bottom: 1, left: 1.7638888888888886 }
              }
            },
          })
        }

        function somarStrings (str1, str2) {
          let valor1 = Number(str1)
          if (isNaN(valor1)) {
            valor1 = 0
          }
          let valor2 = Number(str2)
          if (isNaN(valor2)) {
            valor2 = 0
          }
          return valor1 + valor2
        }
        var doc = new JsPDF({
          orientation: 'landscape',
        })
        const dateNow = (new Date()).toLocaleString()
        const docWidth = doc.internal.pageSize.width
        const docHeight = doc.internal.pageSize.height
        var totalPages = '{total_pages_count_string}'
        var i = 2
        dados.itens.forEach(item => {
          item.preco_desconto = item.valor_total / item.quantidade
          item.desconto_negociado = somarStrings(item.desconto_negociado, item.perc_desconto)
        })
        doc.autoTable({
          theme: 'plain',
          columnStyles: {
            codProduto: { halign: 'center', cellWidth: 16 },
            nome: { cellWidth: 80 },
            unidade_medida: { cellWidth: 10 },
            valor_subtotal: { halign: 'right' },
            valor_desconto: { halign: 'right' },
            valor_total: { halign: 'right' },
            desconto_negociado: { cellWidth: 21, halign: 'right' },
            valor_unitario: { minCellWidth: 20, halign: 'right' },
            preco_desconto: { halign: 'right' },
            quantidade: { halign: 'right' },
            volume: { halign: 'right' },
            previsao_compra: { halign: 'right' },
          },
          styles: { lineColor: [0, 0, 0] },
          headStyles: { lineWidth: { top: 0.2, right: 0, bottom: 0.2, left: 0 }, valor_subtotal: { halign: 'right' } },
          columns: [
            { title: 'Código', dataKey: 'codProduto' },
            { title: 'Descrição', dataKey: 'nome' },
            { title: 'UN', dataKey: 'unidade_medida' },
            { title: 'Qtd.', dataKey: 'quantidade' },
            { title: 'Vol.', dataKey: 'volume' },
            { title: 'Preço', dataKey: 'valor_unitario' },
            { title: 'Desc. %', dataKey: 'desconto_negociado' },
            { title: 'Preço Desc.', dataKey: 'preco_desconto' },
            { title: 'Vlr. Bruto', dataKey: 'valor_subtotal' },
            { title: 'Vlr. Desc.', dataKey: 'valor_desconto' },
            { title: 'Vlr. Líquido', dataKey: 'valor_total' },
          ],
          body: dados.itens,

          margin: { top: 43, left: 10, right: 10, bottom: 10 },
          didParseCell: (data) => {
            const trocarPonto = data.column.dataKey === 'valor_unitario' || data.column.dataKey === 'valor_subtotal' || data.column.dataKey === 'valor_desconto' || data.column.dataKey === 'valor_total' || data.column.dataKey === 'desconto_negociado' || data.column.dataKey === 'preco_desconto'
            if (data.row.section === 'body') {
              if (data.cell.text[0] !== '' && trocarPonto) {
                data.cell.text = formataMoney(parseFloat(data.cell.text))
              }

              if (data.column.dataKey === 'desconto_negociado') {
                data.cell.text = data.cell.text + '%'
              }
            }
            if (trocarPonto || data.column.dataKey === 'quantidade' || data.column.dataKey === 'previsao_compra' || data.column.dataKey === 'volume') {
              data.cell.styles.halign = 'right'
            }
          },

          willDrawPage: () => {
            addHeader()
          },
          didDrawPage: (data) => {
            addFooter(data.pageNumber)
          },

        })
        doc.line(margin.left, doc.lastAutoTable.finalY, docWidth - margin.right, doc.lastAutoTable.finalY)
        if (dados.bonificacao.length > 0) {
          addBonificacao(doc, dados.bonificacao)
        }
        let finalY = doc.lastAutoTable.finalY

        doc.setFontSize(fontSize)
        doc.setFont('helvetica', 'bold')
        doc.text('Valor Total: R$ ' + formataMoney(dados.valor_total), 285, finalY += 4, { align: 'right' })
        doc.text('Parcelas: ' + dados.parcelas + ' x R$ ' + parcelamento(formataMoney(dados.valor_total), dados.parcelas), 285, finalY += 4, { align: 'right' })
        doc.setFont('helvetica', 'normal')
        const observacao = dados.observacao ? dados.observacao : ''
        doc.text('Observação: ' + observacao, margin.left + 2, finalY - 2, { maxWidth: 220 })
        if (dados.tipo_atendimento === 0 && dados.signature_id) {
          if (observacao.length > 255) {
            finalY += 10
          }

          if (finalY > 140) {
            doc.addPage()
            addHeader()
            finalY = 43
            doc.line(margin.left, finalY, docWidth - margin.right, finalY)
          }
          finalY += 9
          doc.line(98, finalY, 198, finalY)
          doc.line(98, finalY, 98, finalY + 44)
          doc.line(198, finalY, 198, finalY + 44)
          doc.line(98, finalY + 44, 198, finalY + 44)
          doc.text('Assinatura do Produtor', docWidth / 2, finalY += 5, { align: 'center' })
          const baseUrl = `${axios.defaults.baseURL}/public/image/pedidos/signatures/${dados.signature_id}`
          doc.addImage(baseUrl, 'png', 120, finalY - 55, 30, 60, null, 'MEDIUM', 270)
        }
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPages)
        }
        doc.save('Pedido_' + dados.pedido_id + '.pdf')
      },
      editarPedido (pedido) {
        this.dadosPedido = pedido
        this.EDIT_PEDIDO(pedido)
      },
    },
  }
</script>
